import React, { FunctionComponent } from 'react'
import { WidgetBase } from 'Shared/components/builders/WidgetBuilder'
import WidgetContainer from 'Shared/components/builders/WidgetContainer'

import EasySoftwareSearchBanner, {
  EasySoftwareSearchBannerType,
} from '../EasySoftwareSearchBanner/EasySoftwareSearchBanner'

export interface WidgetEasySoftwareSearchBannerType
  extends WidgetBase,
  EasySoftwareSearchBannerType {
  contentType: 'EasySoftwareSearchBanner'
}

const WidgetEasySoftwareSearchBanner: FunctionComponent<WidgetEasySoftwareSearchBannerType> = (
  props
) => (
  <WidgetContainer
    {...props.puxWidgetProperties}
    {...props.puxWidgetAnimation}
    {...props.metadata}
    fullWidth
  >
    <EasySoftwareSearchBanner
      displayText={props.displayText}
      searchBannerBackground={props.searchBannerBackground}
      searchBannerContact={props.searchBannerContact}
      searchBannerContent={props.searchBannerContent}
      searchBannerSearchTarget={props.searchBannerSearchTarget}
    />
  </WidgetContainer>
)

export default WidgetEasySoftwareSearchBanner
