import './EasySoftwareSearchBanner.scss'

import React, { FunctionComponent } from 'react'
import PuxWysiwyg, {
  PuxWysiwygHtmlBodyType,
  PuxWysiwygType,
} from 'Shared/components/PuxWysiwyg/PuxWysiwyg'

import PuxMediaSelector, {
  PuxMediaSelectorType,
} from '../content/mediaImage/PuxMediaSelector'
import EasySoftwareSearch from '../EasySoftwareSearch/EasySoftwareSearch'

export interface EasySoftwareSearchBannerType {
  contentItemId?: string
  displayText: string
  searchBannerContent: PuxWysiwygHtmlBodyType
  searchBannerContact: PuxWysiwygHtmlBodyType
  searchBannerSearchTarget: 'header' | 'knowledgebase'
  searchBannerBackground: PuxMediaSelectorType
}

const rootClass = `search-banner`

const EasySoftwareSearchBanner: FunctionComponent<EasySoftwareSearchBannerType> = (
  props
) => {
  return (
    <div className={rootClass}>
      <div className={`${rootClass}-image`}>
        <PuxMediaSelector
          disableLazyload={true}
          {...props.searchBannerBackground}
        />
      </div>

      <div className={`${rootClass}-foreground`}>
        <div className={`${rootClass}-content`}>
          <PuxWysiwyg content={props.searchBannerContent} />
          <EasySoftwareSearch
            searchType={props.searchBannerSearchTarget}
            setLanguageDialogOpen={() => true}
          />
          <PuxWysiwyg content={props.searchBannerContact} />
        </div>
      </div>
    </div>
  )
}

export default EasySoftwareSearchBanner
